/* Nav.css */

.nav-container {
  position: absolute;
  display: flex;
  top: 0;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 10;
}

.innerContainer {
  position: absolute;
  background-color: var(--primary-transparent-background-color);
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 850px;
  padding-right: 8px;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  z-index: 10;
}

.logo-and-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: 'Rajdhani', sans-serif;
}

.nav-logo {
  width: 80px;
  height: 80px;
  padding: 10px;
  object-fit: contain;
}

.nav-title {
  margin-right: 10px;
  font-size: 2.5rem;
  color: white;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bar {
  font-size: 1.5rem;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.nav-item {
  position: relative;
  padding: 0 20px;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item i {
  display: none;
}

.nav-item:hover {
  opacity: 1;
  font-weight: bold;
  background-color: white;
  color: black;
}

.nav-item-selected {
  opacity: 1;
  font-weight: bold;
  background-color: transparent;
  color: white;
  text-decoration: none;
}

.nav-item-selected::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 4px solid red;
}

.nav-bar:hover .nav-item:not(.nav-item-selected) {
  text-decoration: none;
}

.nav-stop-button {
  padding-left: 1rem;
  font-size: 1.3rem;
}

.optionsButton {
  margin-left: 1rem;
}

.dropdown-menu {
  position: absolute;
  background-color: rgba(106, 51, 255, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 20;
  padding: 5px;
  width: 150px;
  right: 0;
  top: 50px;
}

.dropdown-menu button {
  font-size: 1rem;
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  padding: 5px;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.dropdown-menu button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-menu i {
  padding-right: 5px;
}

.dropdown-separator {
  height: 0.7px;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 2px 0;
}

.lang-dropdown {
  position: absolute;
  background-color: rgb(106, 51, 255, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 20;
  width: 90px;
  right: 0;
  top: 50px;
}

.lang-dropdown button {
  font-size: 1rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 0px 10px;
  border: solid rgba(255, 255, 255, 0.5) 1px;
}

.lang-dropdown button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.nav-container .innerContainer .vol-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-right: 20px;
}

.nav-container .innerContainer .vol-container input {
  width: 120px;
  accent-color: rgb(106, 51, 255);
}

.nav-container .innerContainer .vol-container button {
  font-size: 1.2rem;
  background-color: transparent;
  color: rgb(255, 255, 255);
}

@media screen and (max-width: 765px) {
  .logo-and-title {
    width: 100%;
  }
  .nav-logo {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  .nav-title {
    font-size: 1.5rem;
  }
  .nav-title {
    font-size: 1.5rem;
  }
  .nav-bar {
    font-size: 1.2rem;
  }
  .nav-container .innerContainer .vol-container button {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 510px) {
  .nav-logo {
    margin-left: 2rem;
    height: 60px;
    width: 60px;
  }
  .nav-title {
    margin-right: 2rem;
    font-size: 2rem;
  }
  .innerContainer {
    height: 100px;
  }
  .nav-container .innerContainer .vol-container button i {
    display: block;
    padding-bottom: 9px;
  }
  .nav-item i {
    display: flex;
    padding-bottom: 10px;
  }
  .label {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}

@media screen and (max-width: 370px) {
  .logo-and-title {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .nav-container .innerContainer .vol-container {
    gap: 10px;
    padding-right: 10px;
  }

  .nav-container .innerContainer .vol-container input {
    width: 80px;
  }
  .optionsButton {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 495px) {
  .dropdown-menu {
    top: 30px;
  }
}
