.BottomBarApp {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  overflow: hidden;
}

.BottomBarApp main {
  flex: 1;
  height: calc(100dvh - 5rem);
}

.BottomBarApp .bottom-bar {
  height: 5rem;
}
