.reg-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 10px;
}

.reg-container input[type="text"],
.reg-container input[type="password"] {
  flex-grow: 1;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  color: black;
}

.reg-container button {
  padding: 10px 20px;
  background-color: rgba(106, 51, 255, 0.8);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.reg-container button:hover {
  background-color: #0056b3;
}

.reg-container button[disabled] {
  background-color: #667f99;
}

.reg-container button[disabled]:hover {
  background-color: #8fa0b3;
}

.reg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  padding: 2.5vh 5vw;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2);
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.error {
  color: yellow;
  font-weight: 500;
  font-style: italic;
}

.Verify {
}
