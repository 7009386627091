@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap");

@import "./theme.css";

body.full-screen {
  background-color: #777777;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
}

[hidden] {
  display: none !important;
}

:root {
  --primary-transparent-background-color: rgba(106, 51, 255, 0.2);
}
