main {
  display: flex;
  flex-direction: column;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  overflow: auto;
  flex-grow: 1;
}

.music-icon {
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s;
}

.scene-card {
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s;
}

.scene-card:hover {
  transform: translateY(-10px);
}

.scene-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.scene-info {
  padding: 10px;
}

.scene-card .scene-info .prompt-text {
  font-size: 1rem;
  font-weight: bold;
}

.scene-card .scene-info .style-name {
  font-size: 0.875rem;
  font-weight: normal;
}

.buttons-header {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-bottom: 1rem;
}

.search-bar,
.filters {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 10px;
}

.search-bar input[type='text'],
.filters select {
  flex-grow: 1;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  color: black;
}

.filters input[type='text'] {
  flex-grow: 1;
  padding: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  color: black;
}

.search-bar button,
.filters input[type='checkbox'],
.filters button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.search-bar button:hover,
.filters button:hover {
  background-color: #0056b3;
}

.filters label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.filters input[type='checkbox'] {
  accent-color: #007bff;
  margin: 0 10px 0 0;
}

.filters input[type='text']:disabled {
  background-color: #ddd;
  color: #666;
}

.filters input[type='text'] {
  flex-grow: 0;
  width: 200px;
}

.pagination-controls {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 20px;
  gap: 2rem;
}

.pagination {
  display: inline;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:focus {
  outline: none;
}

.pagination button:hover {
  outline: #fff solid 1px;
}

.pagination button.current {
  background-color: #007bff;
  color: #fff;
}

.pagination button.disabled {
  pointer-events: none;
  color: black;
}

.pagination button.ellipsis {
  pointer-events: none;
}

.pagination-controls .results-per-page {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* font-size: 18px; */
}

.pagination-controls .results-per-page .page-selector-dropdown {
  background-color: #ddd;
  color: black;
}

/* .pagination-controls .results-per-page span {
  font-family: 'Rajdhani', sans-seri;
  font-weight: bold;
} */

@media screen and (max-width: 770px) {
  main {
    font-size: 15px;
  }
  .search-bar,
  .filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
    gap: 10px;
  }

  .search-bar input[type='text'],
  .filters input[type='text'],
  .filters select {
    flex-grow: 1;
    padding: 0px;
    padding-left: 10px;
    margin: 0px;
  }

  .search-bar button,
  .filters input[type='checkbox'],
  .filters button {
    padding: 0px 10px;
    border: none;
    border-radius: 5px;
  }

  .pagination-controls {
    gap: 0.2rem;
    margin: 1rem;
  }
}
