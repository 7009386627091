.lang-Section {
  height: 30px;
  margin-bottom: 40px;
  position: relative;
}

.lang-button {
  padding: 5px 10px;
  background-color: rgba(106, 51, 255, 0.8);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.lang-dropdown {
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 20;
  width: 90px;
  top: 0rem;
}

.lang-dropdown button {
  font-size: 1rem;
  background-color: rgba(106, 51, 255, 0.1);
  color: rgb(255, 255, 255);
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 0px 10px;
  margin: 0;
  border: solid rgba(255, 255, 255, 0.5) 1.5px;
}

.lang-dropdown button.selected {
  color: rgba(106, 51, 255, 1);
  background-color: rgba(255, 255, 255, 0.9);
}
