.SideBarApp {
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
}

.side-menu {
  width: 5rem;
  height: 100vh;
  transition: width 0.3s;
  background-color: rgba(0, 0, 0, 0.8);
}

.side-menu.open {
  width: 13rem;
  background-color: rgba(106, 51, 255, 0.6);
}

.side-menu.open .side-menu--logo {
  display: flex;
}

.side-menu--logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: #fff;
  width: 40px;
  min-width: 40px;
  height: 40px;
  padding: 0.2rem 0.2rem;
}

.side-menu--logo img {
  object-fit: contain;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.toggleButton {
  display: none;
  padding: 0.2rem 0.5rem;
  color: white;
  height: fit-content;
  margin: auto;
}

.side-menu.open .toggleButton {
  display: block;
}

.fa-bars {
  font-size: 1.3rem;
}

.side-menu .side-menu--logo {
  margin: 1rem 1.25rem;
}
.side-menu.open .side-menu--logo {
  margin: 1rem;
}

.side-menu .side-menu--item {
  margin: 0.5rem;
}

.side-menu.open .side-menu--item {
  margin: 1rem;
}

.side-menu--item {
  display: flex;
  gap: 0.5rem;

  border-radius: 15px;
}

.side-menu--item:hover {
  background-color: white;
}

.side-menu--item:hover .side-menu--label {
  color: black;
}

.side-menu--item:hover .side-menu--icon {
  color: black;
}

.side-menu--item.selected {
  background-color: white;
}

.side-menu--item.selected .side-menu--label {
  color: black;
}

.side-menu--item.selected .side-menu--icon {
  color: black;
  background-color: white;
}

.side-menu--label {
  display: none;
  font-family: 'Rajdhani', sans-seri;
  color: white;

  font-size: 1.2rem;
  font-weight: bold;
}

.side-menu--icon {
  color: white;
  margin: 0.5rem;
}

.side-menu.open .side-menu--label {
  display: inline-block;
}

.side-menu--header {
  display: flex;
  justify-content: space-between;
}

.side-menu--title {
  display: none;
  align-items: center;
}

.side-menu.open .side-menu--title {
  display: flex;
}

.side-menu--title span {
  color: white;
  font-family: 'Rajdhani', sans-seri;
  font-weight: bold;
  font-size: 1.6rem;
}

.side-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-menu a {
  padding: 1rem;
  align-items: center;
}

.SideBarApp main {
  background-color: rgba(0, 0, 0, 0.7);
  flex-grow: 1;
  color: white;
  max-height: 100dvh;
  overflow: auto;
}
