.Package {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.Package .package-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Package .package-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem;
  width: 18.75rem;
  cursor: pointer;
}

.Package .package-option:hover {
  border-color: var(--theme-text-color);
  background-color: var(--theme-hover-background-color);
}

.Package .package-option .package-option-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--theme-text-color);
}

.Package .package-option .package-option-description {
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
}
