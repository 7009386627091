.Credit {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.Credit .option {
  display: block;
  margin: 1.25rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 1rem;
  text-align: center;
  width: 18.75rem;
  background-color: #f9f9f9;
}

.Credit .option .option-title {
  color: #333; /* Darker color for headings */
  font-weight: bold;
}

.Credit .option .option-description {
  color: #666; /* Lighter color for descriptions */
  margin-top: 0.5rem;
}
