.bottom-menu {
  z-index: 1;
}

.bottom-menu ul {
  transition: width 0.3s;
  background-color: rgb(106, 51, 255, 0.8);
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  justify-content: space-around;
}

.bottom-menu li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bottom-menu .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
  padding: 0.5rem;
  padding-bottom: 0;
}

.bottom-menu .label {
  text-align: center;
  color: white;
  font-family: 'Rajdhani', sans-seri;
  font-size: 1rem;
  font-weight: bold;
  padding-top: 3px;
}

@media screen and (max-width: 410px) {
  .bottom-menu .label {
    display: none;
  }
  .bottom-menu .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 1.5em;
  }
}
